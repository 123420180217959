<template>
  <div class="model page">
    <section class="section">
      <h1 class="title">login</h1>
      <google-firebase-o-auth url="/"></google-firebase-o-auth>
    </section>
  </div>
</template>

<script>
import GoogleFirebaseOAuth from "../components/GoogleFirebaseOAuth.vue";
export default {
  components: { GoogleFirebaseOAuth },
  mounted() {},
  methods: {},
};
</script>

<style></style>
